/* eslint-disable */
import { createBill, createLetter, getDebtors, checkPeople, formatMissing } from "./utils.js";

import SwissQRBill from "swissqrbill/bundle";
import axios from "axios"
export const createDocument = async (creditor, debtors, letter, setTotal) => {
    const amount = 95.00
    let counter = 0
    const data = {
        currency: "CHF",
        amount: parseFloat(letter.amount),
        creditor
    };
    //const stream = new BlobStream();
    const stream = new SwissQRBill.BlobStream()
    const pdf = new SwissQRBill.PDF(data, stream, { size: "A4", autoGenerate: false })
    const calibril = await axios.get(require('./fonts/calibril.ttf'), { responseType: "arraybuffer" })
    const calibri = await axios.get(require('./fonts/calibri.ttf'), { responseType: "arraybuffer" })
    const imageData = {
        slogan: await getImage(require('./logos/logo_text.png'))
    }

    pdf.registerFont("normal", calibril.data)
    pdf.registerFont("bold", calibri.data)
    
    for (let person of debtors) {
        if (person.address && person.zip_code && person.town) {
            createLetter(person, parseFloat(letter.amount), pdf, letter, imageData, creditor)
            createBill(person, pdf, data, imageData)
        } 
        counter++;
        if(!(counter%10)){
            setTotal(counter/debtors.length)
            await new Promise(r => setTimeout(r, 1));
        }
    }
    pdf.end()
    return await new Promise(resolve => {
        pdf.on("finish", () => {
          resolve(stream.toBlobURL("application/pdf"));
        });
      });

}

export const checkData = (setMissing)=>{

    const [debtors, missing] = checkPeople(allDebtors)
    const formatedMissing = formatMissing(missing)
    setMissing(formatedMissing)
}

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

const getImage = async (location)=>{
    return await new Promise(async (resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL((await axios.get(location, {  responseType: "blob", })).data);
      });
  
}

