import { createStore } from 'vuex'

export default createStore({
  state() {
      return {
          total: 0.0
      }
  },
  mutations: {
      setTotal(state, total){
          state.total = total
      },
  },
  actions: {
    async setPercentage(context, payload){
        context.dispatch("setPercentage", payload)
    }
  }
})
