<template>
  <div class="">
    <label class="block text-gray-700 text-sm font-bold mb-1" for="">
      {{ label }}
    </label>
    <input
    :value="value"
    @input="update"
      class="
        appearance-none
        border
        rounded
        w-full
        py-2
        px-3
        text-gray-700
        leading-tight
        focus:outline-none focus:shadow-outline
      "
      :id="label"
      :type="type"
      :placeholder="label"
    />
  </div>
</template>

<script>
export default {
  props: ["label", "type", "modelValue", "validate"],
  emits: ["update:modelValue"],
  data(){
    return {value: undefined}
  },
  methods:{
    update(event){
      this.value = event.target.value
      
      this.$emit(
				"update:modelValue", this.type=="number"?parseInt(this.value):this.value
			)
    },
  },
  created(){
    this.value = this.modelValue
  }
};
</script>