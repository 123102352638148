<template>
  <div class="flex flex-row justify-center p-4">
    <CardFrame class="w-full flex flex-row">
      <h1 class="text-4xl font-bold">Gloggi Bill Creator & Data Checker</h1>
      <SwitchMode @switchMode="switchMode" />
      <div v-if="error" class="rounded-lg bg-red-200 border border-red-700 p-3">
        <h2 class="text-2xl font-bold">Fehler: {{ error }}</h2>
      </div>
      <CardFrame>
        <h2 class="text-2xl font-bold">Status: <span class="text-gray-700">{{ status.message }}</span></h2>
        <div v-if="status.status=='createBill'" class="w-full bg-gray-200 rounded-full dark:bg-gray-700">
          <div class="bg-red-700 text-xs font-medium text-blue-100 text-center p-1 leading-none rounded-full"
            :style="`width: ${getTotal}%`"> {{ getTotal }}%</div>
        </div>
        <div v-if="download.excel&&download.pdf" class="flex space-x-2">
          <a :download="`Jahresrechnungen_${letter.year}`" :href="download.pdf" class="
            flex space-x-1 
            bg-red-700
            hover:bg-red-500
            rounded-lg
            p-1 px-3
            text-center text text-white
          ">
        <SaveIcon class="w-5 h-5" /> <span>Jahresrechnungen speichern</span>
        </a>
        <a :download="`Zahlungscheck_Jahresrechnungen_${letter.year}`" :href="download.excel" class="
            flex space-x-1 
            bg-red-700
            hover:bg-red-500
            rounded-lg
            p-1 px-3
            text-center text text-white
          ">
        <SaveIcon class="w-5 h-5" /> <span> Zahlungscheck Excel speichern</span>
        </a>
        </div>
        <BasicSpinner v-if="status.status=='dataCheck'" />

      </CardFrame>
      <div class="flex flex-col md:flex-row space-y-4 md:space-y-0  md:space-x-4">
        <CardFrame class="">
          <h2 class="text-2xl font-bold">MiData</h2>
          <InputField v-model="addressList.url" label="Link zu Adressliste" />
          <InputField v-model="addressList.token" label="API Token" />
        </CardFrame>
        <CardFrame v-if="mode == 'bill'" class="">
          <h2 class="text-2xl font-bold">Rechnungsdaten</h2>
          <InputField v-model="creditor.name" label="Name" />
          <InputField v-model="creditor.address" label="Addresse" />
          <div class="flex space-x-2">
            <InputField class="w-1/3" v-model="creditor.zip" type="number" label="PLZ" />
            <InputField class="w-2/3" v-model="creditor.city" label="Ort" />
          </div>
          <InputField v-model="creditor.account" label="IBAN" validate="iban" />
          <InputField v-model="creditor.email" label="E-Mail" />
        </CardFrame>
        <CardFrame v-if="mode == 'bill'" class="">
          <h2 class="text-2xl font-bold">Briefdaten</h2>
          <div class="flex space-x-2">
            <InputField class="w-2/3" v-model="letter.dateAndPlace" label="Datum und Ort" />
            <InputField class="w-1/3" v-model="letter.year" label="Jahr" />
          </div>
          <InputField type="number" class="w-1/3" v-model="letter.amount" label="Rechnungsbetrag" />
        </CardFrame>

      </div>
      <label v-if="missing" class="block text-red-700 text-sm font-bold mb-1" for="">Fehlende Angaben</label>
      <textarea class="
          appearance-none
          border
          border-red-700
          rounded
          w-full
          py-2
          px-3
          text-red-700
          leading-tight
          focus:outline-none focus:shadow-outline
        " rows="10" v-if="missing" v-model="missing"></textarea>

      <button @click="startProcess" class="
            bg-red-700
            hover:bg-red-500
            rounded-lg
            p-3
            text-center text-xl text-white
          ">
        Start
      </button>
    </CardFrame>
  </div>
</template>

<script>
import { createDocument } from "./logic/billCreator.js";
import { checkPeople, formatMissing, getDebtors, addUID } from "./logic/utils.js";
import InputField from "./components/InputField.vue";
import CardFrame from "./components/CardFrame.vue"
import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import SwitchMode from "./components/SwitchMode.vue";
import BasicSpinner from "./components/BasicSpinner.vue"
import SaveIcon from "./components/SaveIcon.vue"
import XLSX from 'xlsx';
export default {
  name: "App",
  components: { InputField, CardFrame, SwitchMode, BasicSpinner, SaveIcon },
  data() {
    return {
      mode: "bill",
      creditor: {
        name: "Pfadikorps Glockenhof",
        address: "Sihlstrasse 33",
        zip: 8001,
        city: "Zürich",
        account: "CH3409000000800171643",
        country: "CH",
        email: "info@gloggi.ch"
      },
      addressList: {
        url: undefined,
        token: undefined
      },
      letter: {
        dateAndPlace: `Zürich, ${format(new Date(), " MMMM yyyy", { locale: de })}`,
        year: format(new Date(), "yyyy", { locale: de }),
        amount: 95.00,
      },
      percentage: 0,
      percentageKey: 0,
      missing: undefined,
      status: {
        status: "start",
        message: "Klicke auf Start um mit dem Prozess zu beginnen"
      },
      debtors: undefined,
      download: {
        excel: undefined,
        pdf: undefined
      },
      error: undefined
    };
  },
  methods: {
    switchMode(mode) {
      this.mode = mode
    },
    async startProcess() {
      try{
        this.validateData()
      }catch(e){
        this.error = e.message
        return 
      }
      this.status = {
        status: "dataCheck",
        message: "Daten werden von MiData abgerufen und geprüft"
      }
      const allDebtors = await getDebtors(this.addressList.url, this.addressList.token)
      const [debtors, missing] = checkPeople(allDebtors)
      this.debtors = debtors
      addUID(debtors)
      this.missing = formatMissing(missing)
      if (this.mode == 'bill') {
        this.status = {
        status: "createBill",
        message: "Die Rechnungen werden erstellt"
      }
        const blobURL = await createDocument(this.creditor, debtors, this.letter, (total) => { this.setTotal(total) });
        this.download.excel = this.createExcel(debtors);
      this.download.pdf= blobURL;
      }
      this.status = {
        status: "end",
        message: "Der Prozess ist beendet"
      }

    },
    setTotal(total) {
      this.$store.commit("setTotal", total)
    },
    increasePercentage(percentage) {
      this.$store.dispatch("setPercentage", percentage)
    },
    createExcel(people){
      const formattedPeople = people.map(p=>{
        return {
          "ID": p.id,
          "Vorname": p.first_name,
          "Nachname": p.last_name,
          "Pfadiname": p.nickname,
          "E-Mail": p.email,
          "Adresse": p.address,
          "PLZ": p.zip_code,
          "Ort": p.town,
          "Abteilung": p.section,
          "Gruppe": p.primary_group_name,
          "Zahlungsinfo": p.uid
        }
      })
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(formattedPeople)
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      return URL.createObjectURL(blob);
    },
    validateData() {
      this.error = undefined
      if (!this.addressList || typeof this.addressList !== 'object') {
      throw new Error('Adressliste Objekt fehlt oder ist ungültig.');
    }
    if (!this.addressList.url || typeof this.addressList.url !== 'string') {
      throw new Error('Adressliste URL fehlt oder ist ungültig.');
    }
    if (!this.addressList.token || typeof this.addressList.token !== 'string') {
      throw new Error('Adressliste Token fehlt oder ist ungültig.');
    }
    if(this.mode !=="bill"){
      return true
    }
    
    if (!this.creditor || typeof this.creditor !== 'object') {
      throw new Error('Kreditor Objekt fehlt oder ist ungültig.');
    }
    if (!this.creditor.name || typeof this.creditor.name !== 'string') {
      throw new Error('Kreditor Name fehlt oder ist ungültig.');
    }
    if (!this.creditor.address || typeof this.creditor.address !== 'string') {
      throw new Error('Kreditor Adresse fehlt oder ist ungültig.');
    }
    if (!this.creditor.zip || typeof this.creditor.zip !== 'number') {
      throw new Error('Kreditor PLZ fehlt oder ist ungültig.');
    }
    if (!this.creditor.city || typeof this.creditor.city !== 'string') {
      throw new Error('Kreditor Stadt fehlt oder ist ungültig.');
    }
    if (!this.creditor.account || typeof this.creditor.account !== 'string') {
      throw new Error('Kreditor Konto fehlt oder ist ungültig.');
    }
    if (!this.creditor.country || typeof this.creditor.country !== 'string') {
      throw new Error('Kreditor Land fehlt oder ist ungültig.');
    }

   
    

  
    if (!this.letter || typeof this.letter !== 'object') {
      throw new Error('Brief Objekt fehlt oder ist ungültig.');
    }
    if (!this.letter.dateAndPlace || typeof this.letter.dateAndPlace !== 'string') {
      throw new Error('Brief Datum und Ort fehlt oder ist ungültig.');
    }
    if (!this.letter.year || typeof this.letter.year !== 'string') {
      throw new Error('Brief Jahr fehlt oder ist ungültig.');
    }
    if (isNaN(this.letter.amount)) {
      throw new Error('Brief Betrag fehlt oder ist ungültig.');
    }

    return true;
  }
  },
  computed: {
    getPercentage() {
      return this.$store.state.percentage
    },
    getTotal() {
      return (this.$store.state.total * 100).toFixed(2)
    },
    getMissing() {
      return this.$store.state.missing
    }
  }
};
</script>
  

