/* eslint-disable */
import axios from "axios"


export const createBill = (person, pdf, data, imageData) => {
    data.debtor = {
        name: `${person.first_name} ${person.last_name}`,
        address: person.address,
        zip: parseInt(person.zip_code),
        city: person.town,
        country: "CH"
    }
    data.additionalInformation = person.uid
    pdf.addPage();
    //pdf.image(require('./logos/logo.png'), 71, 40, { width: 91 })
    pdf.image(imageData.slogan, 71, 40, { width: 438 })
    pdf.addQRBill();
}

export const createLetter = (person, amount, doc, letter, imageData, creditor) => {
    const text1 = `Liebe Biber, Wölfe, Pfadis und Pios
Liebe Eltern 

Ihr Kind ist Mitglied in einer der sieben Abteilungen des Pfadikorps Glockenhof. Mit diesem Schreiben erhalten Sie die Rechnung für den Jahresbeitrag ${letter.year}. Dieser beläuft sich auf `
    const text2 = `. Wir bitten Sie höflich, obigen Betrag innert 30 Tagen auf unser Postkonto zu überweisen.
Mit dem Jahresbeitrag decken wir die laufenden Kosten für den Pfadibetrieb, die Mitglieder-zeitschrift «Goldener Pfeil», Verbandsabgaben und Versicherungen. Eine grobe Aufschlüsse-lung des Mitgliederbeitrags sowie ein Formular für den Unterstützungsfonds, falls diese Kos-ten für Ihre Familie Schwierigkeiten bereiten sollten, befinden sich auf unserer Website unter www.gloggi.ch/was-wir-tun.

Falls Sie diese Rechnung erhalten, obwohl Ihr Kind nicht mehr Mitglied des Pfadikorps 
Glockenhof ist, so entschuldigen wir uns und möchten Sie bitten, Ihr Kind noch einmal per Post oder per Email (sekretariat@gloggi.ch) über unser Sekretariat abzumelden.

Bei Fragen stehen wir gerne unter info@gloggi.ch zur Verfügung.


Mit Freud debi, Euses Bescht, Allzeit Bereit, Zeme Wyter und Bewusst Handeln

Lindo & Chihiro
`
    const dateAndPlace = letter.dateAndPlace
    const letterHead = `Mitgliederbeitrag ${letter.year} Pfadikorps Glockenhof, Abteilung ${person.section}, Gruppe ${person.primary_group_name}`
    const sender = `${creditor.name}
${creditor.address}
${creditor.zip}  ${creditor.city}
E-Mail: ${creditor.email}
`
person.address = person.address.trim()
    const address = `An die Eltern von:
${person.first_name} ${person.last_name} ${person.nickname? "v/o "+ person.nickname: ""}
${person.address}
${person.zip_code} ${person.town}`
    const korpsleader = "Davud Evren v/o Lindo und Lynn Farner v/o Chihiro (Korpsleitung)"
    
    const range = doc.bufferedPageRange();
    const pages = range.start + range.count
    if(pages>0){
    doc.addPage({size: "A4",margins: {
        top: 275,
        bottom: 0,
        left: 71,
        right: 71
      }})
    }
      
    //doc.image(require('./logos/logo.png'), 71, 40, { width: 91 })
    doc.image(imageData.slogan, 71, 40, { width: 438 })
    doc.image(require('./logos/lindo_sig.jpg'), 71, 723, { width: 72 })
    doc.image(require('./logos/chihiro_sig.png'), 204, 723, { width: 75 })
    doc.font("normal")
    doc.fontSize(12);
    //
    doc.text(dateAndPlace)
    doLineBreaks(doc, 2)
    doc.font("bold")
    doc.text(letterHead)
    doc.font("normal")
    doLineBreaks(doc, 2)
    doc.text(text1, { continued: true })
    doc.font("bold")
    doc.text(`CHF ${amount}`, { continued: true })
    doc.font("normal")
    doc.text(text2)
    doLineBreaks(doc, 5)
    doc.text(korpsleader)
    doc.text(sender, 71, 115)
    doc.text(address, 354, 144)
}
const formatUrl = (url) =>{
    const urlParts = url.split('/');
    const groupId = urlParts[urlParts.indexOf('groups') + 1];
  const mailingListId = urlParts[urlParts.indexOf('mailing_lists') + 1];
    return `https://db.scout.ch/de/groups/${groupId}/mailing_lists/${mailingListId}.json`;
  }

export const getDebtors = async (url, token)=>{
    const response = await axios.get(`https://bill.gloggi.ch/midataref/?url=${formatUrl(url)}?token=${token}`, {
        headers:{}})
    const people = response.data["linked"]["people"]
    const groups = Array.from(new Set(people.map(p=>p.primary_group_id)))
    console.log(groups)
    const groupToSection = await findSectionOfGroups(groups, token)
   

    return people.map(p=>{return {...p, section:  groupToSection[p.primary_group_id] }})
}

const findSectionOfGroups = async(groups, token)=>{
    const groupToSection = {}
    for(let i=0;i<groups.length;i++){
        const response = await axios.get(`https://bill.gloggi.ch/midataref/?url=https://db.scout.ch/de/groups/${groups[i]}.json?token=${token}`, {
        headers:{}})
        if(groups[i]&&response.data.linked){
        groupToSection[groups[i]] = response.data.linked.groups.find(g=>g.group_type=="Abteilung")?.name
    }
    }
    return groupToSection

}

export const checkPeople = (people)=>{
    const missing = [];
    const complete =[];

  for (let i = 0; i < people.length; i++) {
    const person = people[i];
    const keys = Object.keys(person);

    if (
      !people[i].first_name ||
      !people[i].last_name ||
      !people[i].address ||
      !people[i].zip_code ||
      !people[i].town
    ) {
      missing.push(person);
    }else{

        complete.push(person)
    }
  }

  return [complete, missing];
}

export const addUID = (people)=>{
    for(let person of people){

        if(!person.section){
            console.log(person)
        }
        person.uid = `${person.section?.substring(0,3).toUpperCase()}:${person.first_name.substring(0,2)}.${person.last_name.substring(0,2)}`
    }
}

export const formatMissing = (missing)=>{
    const grouped = {};


    for (const obj of missing) {
      const section = obj.section;
      if (!grouped[section]) {
        grouped[section] = [];
      }
      grouped[section].push(obj);
    }
    let result = ''
    for (const section in grouped) {
      result += `\nAbteilung ${section}:\n`;
      for (const obj of grouped[section]) {
        const missingKeys = [];
        if (!obj.first_name) missingKeys.push('Vorname');
        if (!obj.last_name) missingKeys.push('Nachname');
        if (!obj.address) missingKeys.push('Addresse');
        if (!obj.zip_code) missingKeys.push('PLZ');
        if (!obj.town) missingKeys.push('Ort');
  
        result += ` - ${obj.first_name} ${obj.last_name}: `;
        if (missingKeys.length !== 0) {
            result += `Fehlende Angaben: ${missingKeys.join(', ')}\n`;
        } 
      }
    }
  
    return result;
}

export const getGroupChildren = async (id, sectiondict, section)=>{
    const response = await axios.get(`https://db.scout.ch/de/groups/${id}.json?token=YesSo-sK4aSBbxH3mxwUReTo8NsWBPg3bysBki3pz8rH3yAV2A`)
    const children = response.data["groups"][0]["links"]["children"]
    const groupType = response.data["groups"][0]["group_type"]
    const name = response.data["groups"][0]["name"]
    const identity = response.data["groups"][0]["id"]
    if(groupType==="Abteilung"){
        section = name
    }else{
        sectiondict[identity] = section
    }
    if(children){
        for(let child of children)
        await getGroupChildren(child, sectiondict, section)
    }
}
const doLineBreaks = (d, amount) => {
    for (let i = 0; i < amount; i++) {
        d.text("\n")
    }
}
