<template>
  <div class="
        flex flex-col
        border border-gray-300
        rounded-md
        p-4
        space-y-4
        w-full
      ">
      <slot></slot>
      </div>
</template>

<script>
export default {

}
</script>

<style>

</style>