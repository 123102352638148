<template>
    <div class="flex flex-row justify-center space-x-2 w-full">
        <button @click="switchTo('bill')" :class="`rounded-lg p-1 border border-red-700 md:w-1/6 text-center font-semibold ${mode=='bill'?'bg-red-700 text-white':''}`">Datencheck & Rechnung</button>
        <button @click="switchTo('check')" :class="`rounded-lg p-1 border border-red-700 md:w-1/6 text-center font-semibold ${mode=='check'?'bg-red-700 text-white':''}`">Nur Datencheck</button>
    </div>

</template>
<script>
export default {
    name: "SwitchMode",
    emits: ["switchMode"],
    data() {
        return {
            mode: "bill"
        }
    },
    methods: {
        switchTo(mode){
            this.mode = mode;
            this.$emit("switchMode",mode)

    }
    },

}
</script>